@font-face {
  font-family: 'AvenirNext';
  src: url('AvenirNext-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'AvenirNextDemiBold';
  src: url('AvenirNext-DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gloria';
  src: url('GloriaHallelujah.ttf') format('truetype');
}

.strong {
  font-family: "AvenirNextDemiBold", Helvetica, Arial, sans-serif;
}

.sketch-writing {
  font-family: "Gloria", Georgia, serif;
}

blockquote.large {
  font-size: 28px;

  @media #{$small-only} {
    font-size: 18px;
  }
}

.red-under {
  color: $body-font-color;
  border-bottom: 2px solid red;
}

.center-img {
  display: block;
  margin: 0 auto;
}

.header {
  background-image: url("../img/kramer-weydt-snow-1170x357.png");
  background-repeat: no-repeat;
  @media #{$small-only} {
    background-size: cover;
  }
  .primary-heading {
    margin-top: 240px;
    @media #{$small-only} {
      margin-top: 20px;
    }
  }
}

.projects {
  margin-top: 60px;

  .project {
    display: inline-block;
    color: #333;

    h4:hover {
      color: $anchor-color-hover;
    }
  }

  @media #{$small-only} {
    margin-top: 20px;
  }

  > .row:last-child {
    margin-top: 20px;
    @media #{$small-only} {
      margin-top: 0;
    }
  }
}

.project-story {
  p, a { font-size: 22px; }
}

.sub-page-header {
  min-height: 300px;
  background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,0.9)), url("../img/kramer-weydt-snow-1170x357.png");
  padding-top: 120px;

  @media #{$small-only} {
    min-height: auto;
    background-size: cover;
    padding-top: 10px;
  }

  &.recruiter {
    background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,1.0)), url("../img/recruiter-in-use.jpg");
    background-size: cover;
    background-position: center;
  }

  &.self-serve {
    background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,1.0)), url("../img/company-profile-hero.jpg");
    background-size: cover;
    background-position: center;
  }

  &.dropcountr {
    background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,1.0)), url("../img/dropcountr-bg.jpg");
    background-size: cover;
    background-position: center;
  }

  &.patient-facesheet {
    background-image: linear-gradient(rgba(255,255,255,0.1), rgba(255,255,255,1.0)), url("../img/pf-dx-modal.png");
    background-size: cover;
    background-position: center;
  }
}

.project-heading {

  margin-top: 10px;

  @media #{$medium-up} {
    margin-top: -30px;
  }

  h1 { font-size: 48px; }
  h2 { font-size: 24px; }
}

.property-bar {
  padding: 11px 0 10px;
  margin-top: 20px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;

  .project-url, .role, .year {
    margin: 0;
    font-size: 14px;

    @media #{$small-only} {
      text-align: left;
    }
  }
}

.sub-header {
  font-family: "AvenirNext", Helvetica, Arial, sans-serif;
  font-weight: 600;

  margin-top: 30px;
}

.handwritten-box {
  background: #f7f7f7;
  border: 1px solid #e7e7e7;
  padding: 20px;
  h5 {font-style: italic;}
  p, a, span { font-family: "Gloria Hallelujah", Georgia, serif;}
}

.no-margin {
  margin: 0;
}

.no-margin-small {
  @media #{$small-only} {
    margin: 0;
  }
}

// Font Sizes
$KW-xsmall-font: 10;
$KW-small-font: 12;
$KW-medium-font: 14;
$KW-large-font: 18;
$KW-xlarge-font: 22;
$KW-xxlarge-font: 28;

// Prepend margin classes
@for $i from 1 through 10 {
  .prepend-#{$i} { margin-top: 10px * $i; }
  .small-prepend-#{$i} {
    @media #{$small-only} { margin-top: 10px * $i; }
  }
  .medium-prepend-#{$i} {
    @media #{$medium-only} { margin-top: 10px * $i; }
  }
  .large-prepend-#{$i} {
    @media #{$large-up} { margin-top: 10px * $i; }
  }
}

@for $i from 1 through 10 {
  // Append margin classes
  .append-#{$i} { margin-bottom: 10px * $i; }
  .small-append-#{$i} {
    @media #{$small-only} { margin-bottom: 10px * $i; }
  }
  .medium-append-#{$i} {
    @media #{$medium-up} { margin-bottom: 10px * $i; }
  }
  .large-append-#{$i} {
    @media #{$large-up} { margin-bottom: 10px * $i; }
  }

  // margin classes
  .margin-#{$i} { margin: 10px * $i; }
  .margin-left-#{$i} { margin-left: 10px * $i; }
  .margin-right-#{$i} { margin-right: 10px * $i; }

  // Padding classes
  .padding-#{$i} { padding: 10px * $i; }
  .padding-left-#{$i} { padding-left: 10px * $i; }
  .padding-right-#{$i} { padding-right: 10px * $i; }

  .large-padding-#{$i} {
    @media #{$large-up} { padding: 10px * $i; }
  }
  .small-padding-#{$i} {
    @media #{$small-only} { padding: 10px * $i; }
  }

  .ls-border-#{$i} {
    border-width: $i * 1px;
    border-style: solid;
  }
}